// CorporateScreen.js
import React, { useState } from 'react';
import Header from '../components/Header';
import CorporateUsers from '../modules/CorporateUsers';
import CorporateCafes from '../modules/CorporateCafes';

const CorporateScreen = () => {
  const [activeTab, setActiveTab] = useState('CorporateUsers');

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'CorporateUsers':
        return <CorporateUsers />;
      case 'CorporateCafes':
        return <CorporateCafes />;
      default:
        return <CorporateUsers />;
    }
  };

  const styles = {
    container: {
      paddingTop: '80px',
    },
    tabMenu: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      borderBottom: '1px solid #ccc',
    },
    tabItem: {
      margin: '0 15px',
      cursor: 'pointer',
      fontWeight: activeTab === 'CorporateUsers' ? 'bold' : 'normal',
    },
  };

  return (
    <div style={styles.container}>
      <Header title="Corporate" />
      <div style={styles.tabMenu}>
        <span
          style={{ ...styles.tabItem, fontWeight: activeTab === 'CorporateUsers' ? 'bold' : 'normal' }}
          onClick={() => setActiveTab('CorporateUsers')}
        >
          Users
        </span>
        <span
          style={{ ...styles.tabItem, fontWeight: activeTab === 'CorporateCafes' ? 'bold' : 'normal' }}
          onClick={() => setActiveTab('CorporateCafes')}
        >
          Cafes
        </span>
      </div>
      {renderActiveTab()}
    </div>
  );
};

export default CorporateScreen;
