import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Container from '../components/Container';
import { registerCafe } from '../services/apiService';
import { UserContext } from '../context/UserContext';

const CafeRegistrationScreen = () => {
  const [cafeName, setCafeName] = useState('');
  const [address, setAddress] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      console.log("userId: " + user.userId);
      await registerCafe({ cafeName, address, contactNumber, email, userId: user.userId });
      
      navigate('/cafe');
    } catch (error) {
      console.error("Error registering cafe:", error);
      setError(error.message || "An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const styles = {
    pageContainer: {
      minHeight: '100vh',
      paddingTop: '100px', // Padding to create space below the fixed header
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      width: '100%',
      maxWidth: '400px', // Limit form width for readability
    },
    input: {
      padding: '12px',
      fontSize: '16px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      width: '100%',
      boxSizing: 'border-box',
    },
    button: {
      padding: '12px',
      fontSize: '16px',
      color: 'white',
      backgroundColor: '#ff7f00',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      fontWeight: 'bold',
      textAlign: 'center',
    },
    errorMessage: {
      color: '#b71c1c',
      backgroundColor: '#ffebee',
      border: '1px solid #f44336',
      padding: '10px',
      borderRadius: '4px',
      textAlign: 'center',
      fontSize: '0.9rem',
    },
  };

  return (
    <div style={styles.pageContainer}>
      <Header />
      <Container>
        <form style={styles.form} onSubmit={handleSubmit}>
          <h2>Register a Cafe</h2>
          {error && <div style={styles.errorMessage}>{error}</div>}
          <input
            style={styles.input}
            type="text"
            placeholder="Cafe Name"
            value={cafeName}
            onChange={(e) => setCafeName(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="text"
            placeholder="Contact Number"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            required
          />
          <input
            style={styles.input}
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="submit" style={styles.button} disabled={loading}>
            {loading ? 'Registering...' : 'Submit'}
          </button>
        </form>
      </Container>
    </div>
  );
};

export default CafeRegistrationScreen;
