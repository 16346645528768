import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { fetchItems, addItem, upsertItem, deleteItem } from '../services/cosmosdbService';

const CafeCorporateAccounts = () => {
  const { user } = useContext(UserContext);
  const [cafeId, setCafeId] = useState(null);
  const [cafeLinkingCode, setCafeLinkingCode] = useState('');
  const [corporateCode, setCorporateCode] = useState('');
  const [linkedCorporateAccounts, setLinkedCorporateAccounts] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const loadCafeData = async () => {
      if (user) {
        try {
          const assignments = await fetchItems(
            'brewserdb',
            'cafe_assignments',
            "SELECT * FROM c WHERE c.userId = @userId AND c.role = 'admin'",
            [{ name: '@userId', value: user.userId }]
          );

          if (assignments.length > 0) {
            const cafeId = assignments[0].cafeId;
            setCafeId(cafeId);

            const cafes = await fetchItems(
              'brewserdb',
              'cafes',
              "SELECT * FROM c WHERE c.id = @cafeId",
              [{ name: '@cafeId', value: cafeId }]
            );

            if (cafes.length > 0) {
              setCafeLinkingCode(cafes[0].cafeCode);
            }

            const partnerships = await fetchItems(
              'brewserdb',
              'corporate_cafe_partnerships',
              "SELECT * FROM c WHERE c.cafeId = @cafeId",
              [{ name: '@cafeId', value: cafeId }]
            );

            // Fetch company names for each linked corporate account
            const linkedAccountsWithNames = await Promise.all(
              partnerships.map(async (partnership) => {
                const corporateDetails = await fetchItems(
                  'brewserdb',
                  'corporates',
                  "SELECT * FROM c WHERE c.id = @corporateId",
                  [{ name: '@corporateId', value: partnership.corporateId }]
                );
                return {
                  ...partnership,
                  companyName: corporateDetails[0]?.companyName || 'Unknown Company'
                };
              })
            );

            setLinkedCorporateAccounts(linkedAccountsWithNames);
          }
        } catch (err) {
          console.error("Failed to load cafe data:", err);
          setError("Failed to retrieve cafe details.");
        }
      }
    };
    loadCafeData();
  }, [user]);

  const handleLinkCorporate = async () => {
    if (!corporateCode || !cafeId) {
      setError('Please enter a valid corporate code.');
      return;
    }
    setError('');
    setSuccessMessage('');

    try {
      const corporates = await fetchItems(
        'brewserdb',
        'corporates',
        "SELECT * FROM c WHERE c.linkingCode = @corporateCode",
        [{ name: '@corporateCode', value: corporateCode }]
      );

      if (corporates.length === 0) {
        setError('Invalid corporate code.');
        return;
      }

      const corporate = corporates[0];
      const partnership = {
        id: `${cafeId}-${corporate.id}`,
        corporateId: corporate.id,
        cafeId,
        createdAt: new Date().toISOString(),
        status: 'pending',
        dailyCoffees: 0, // Default value
      };

      await addItem('brewserdb', 'corporate_cafe_partnerships', partnership);
      setLinkedCorporateAccounts([...linkedCorporateAccounts, { ...partnership, companyName: corporate.companyName }]);
      setCorporateCode('');
      setSuccessMessage(`Successfully linked with ${corporate.companyName}`);
    } catch (error) {
      console.error('Failed to link with corporate account:', error);
      setError('Failed to link with the corporate account. Please try again.');
    }
  };

  const handleUpdateDailyCoffees = async (partnershipId, newDailyCoffees) => {
    try {
      const partnershipItems = await fetchItems(
        'brewserdb',
        'corporate_cafe_partnerships',
        "SELECT * FROM c WHERE c.id = @partnershipId",
        [{ name: '@partnershipId', value: partnershipId }]
      );

      if (partnershipItems.length === 0) {
        throw new Error('Partnership not found.');
      }

      const partnership = partnershipItems[0];
      partnership.dailyCoffees = parseInt(newDailyCoffees, 10);

      await upsertItem('brewserdb', 'corporate_cafe_partnerships', partnership);

      setLinkedCorporateAccounts((prevAccounts) =>
        prevAccounts.map((account) =>
          account.id === partnershipId ? { ...account, dailyCoffees: partnership.dailyCoffees } : account
        )
      );

      setSuccessMessage('Daily coffees limit updated successfully.');
    } catch (error) {
      console.error('Failed to update daily coffees limit:', error);
      setError('Failed to update daily coffees limit. Please try again.');
    }
  };

  const handleUnlinkCorporate = async (partnershipId) => {
    try {
      await deleteItem('brewserdb', 'corporate_cafe_partnerships', partnershipId);

      setLinkedCorporateAccounts((prevAccounts) =>
        prevAccounts.filter((account) => account.id !== partnershipId)
      );

      setSuccessMessage('Corporate account unlinked successfully.');
    } catch (error) {
      console.error('Failed to unlink corporate account:', error);
      setError('Failed to unlink corporate account. Please try again.');
    }
  };

  const styles = {
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: '50px',
    },
    formContainer: {
      padding: '40px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
      textAlign: 'center',
    },
    linkingCode: {
      fontSize: '18px',
      color: '#333',
      backgroundColor: '#f4f4f4',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '15px',
      borderRadius: '5px',
      border: '1px solid #DDDDDD',
    },
    button: {
      width: '100%',
      backgroundColor: '#F57F17',
      padding: '10px',
      borderRadius: '5px',
      color: '#FFF',
      cursor: 'pointer',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    linkedCorporateList: {
      marginTop: '20px',
      textAlign: 'left',
    },
    dailyCoffeesInput: {
      width: '50px',
      marginLeft: '10px',
      padding: '5px',
    },
    unlinkButton: {
      backgroundColor: '#b71c1c',
      color: '#FFF',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: 'none',
      marginLeft: '10px',
    },
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1>Link Corporate Accounts</h1>

        {cafeLinkingCode && (
          <div style={styles.linkingCode}>
            Cafe Linking Code: {cafeLinkingCode}
          </div>
        )}

        {error && <p style={{ color: 'red' }}>{error}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}

        <input
          style={styles.input}
          type="text"
          placeholder="Enter Corporate Code"
          value={corporateCode}
          onChange={(e) => setCorporateCode(e.target.value)}
        />
        <div style={styles.button} onClick={handleLinkCorporate}>
          Link Corporate Account
        </div>

        <div style={styles.linkedCorporateList}>
          <h3>Linked Corporate Accounts:</h3>
          {linkedCorporateAccounts.length > 0 ? (
            linkedCorporateAccounts.map((account) => (
              <div key={account.id}>
                <p>
                  {account.companyName} - Status: {account.status}
                  <input
                    style={styles.dailyCoffeesInput}
                    type="number"
                    placeholder="Daily Coffees"
                    value={account.dailyCoffees || ''}
                    onChange={(e) => handleUpdateDailyCoffees(account.id, e.target.value)}
                  />
                  <button
                    style={styles.unlinkButton}
                    onClick={() => handleUnlinkCorporate(account.id)}
                  >
                    Unlink
                  </button>
                </p>
              </div>
            ))
          ) : (
            <p>No corporate accounts linked yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CafeCorporateAccounts;
