// CafeScreen.js
import React, { useState } from 'react';
import Header from '../components/Header';
import CafeUsers from '../modules/CafeUsers';
import CafeCorporateAccounts from '../modules/CafeCorporateAccounts';

const CafeScreen = () => {
  const [activeTab, setActiveTab] = useState('CafeUsers');

  const renderActiveTab = () => {
    switch (activeTab) {
      case 'CafeUsers':
        return <CafeUsers />;
      case 'CafeCorporateAccounts':
        return <CafeCorporateAccounts />;
      default:
        return <CafeUsers />;
    }
  };

  const styles = {
    container: {
      paddingTop: '80px',
    },
    tabMenu: {
      display: 'flex',
      justifyContent: 'center',
      padding: '10px 0',
      borderBottom: '1px solid #ccc',
    },
    tabItem: {
      margin: '0 15px',
      cursor: 'pointer',
      fontWeight: activeTab === 'CafeUsers' ? 'bold' : 'normal',
    },
  };

  return (
    <div style={styles.container}>
      <Header title="Cafe" />
      <div style={styles.tabMenu}>
        <span
          style={{ ...styles.tabItem, fontWeight: activeTab === 'CafeUsers' ? 'bold' : 'normal' }}
          onClick={() => setActiveTab('CafeUsers')}
        >
          Users
        </span>
        <span
          style={{ ...styles.tabItem, fontWeight: activeTab === 'CafeCorporateAccounts' ? 'bold' : 'normal' }}
          onClick={() => setActiveTab('CafeCorporateAccounts')}
        >
          Corporate Accounts
        </span>
      </div>
      {renderActiveTab()}
    </div>
  );
};

export default CafeScreen;
