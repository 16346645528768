import React from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate('/');
  };

  const styles = {
    headerContainer: {
      position: 'fixed', // Make the header fixed
      top: 0,
      left: 0,
      right: 0,
      width: '100%',
      maxWidth: '1200px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      margin: '0 auto',
      zIndex: 1000, // Ensure the header stays on top
    },
    logo: {
      fontSize: '50px',
      color: '#333',
      fontWeight: 'bold',
      fontFamily: 'Cowboy Hippie Pro, sans-serif',
      cursor: 'pointer',
    },
    logoutButton: {
      fontSize: '20px',
      color: '#000',
      fontFamily: 'Anton, sans-serif',
      fontWeight: 'normal',
      textDecoration: 'none',
      cursor: 'pointer',
      border: 'none',
      backgroundColor: 'transparent',
      transition: 'color 0.3s ease',
    },
    logoutButtonHover: {
      color: '#494538',
    },
  };

  return (
    <div style={styles.headerContainer}>
      <div style={styles.logo} onClick={() => navigate('/')}>Brewser</div>
      <button
        style={styles.logoutButton}
        onMouseOver={(e) => (e.target.style.color = '#494538')}
        onMouseOut={(e) => (e.target.style.color = '#000')}
        onClick={handleLogout}
      >
        Logout
      </button>
    </div>
  );
};

export default Header;
