import React, { useState, useContext, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import { fetchItems, addItem } from '../services/cosmosdbService';

const CorporateCafes = () => {
  const { user } = useContext(UserContext);
  const [corporateId, setCorporateId] = useState(null);
  const [corporateLinkingCode, setCorporateLinkingCode] = useState(''); // State to store the linking code
  const [cafeCode, setCafeCode] = useState('');
  const [linkedCafes, setLinkedCafes] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const loadCorporateData = async () => {
      if (user) {
        try {
          // Fetch the corporate account associated with the user
          const corporates = await fetchItems(
            'brewserdb',
            'corporates',
            "SELECT * FROM c WHERE c.userId = @userId",
            [{ name: '@userId', value: user.userId }]
          );

          if (corporates.length > 0) {
            const corporate = corporates[0];
            setCorporateId(corporate.id);
            setCorporateLinkingCode(corporate.linkingCode); // Store the linking code
            setLinkedCafes(corporate.linkedCafes || []);
          }
        } catch (err) {
          console.error("Failed to load corporate data:", err);
          setError("Failed to retrieve corporate account details.");
        }
      }
    };
    loadCorporateData();
  }, [user]);

  const handleLinkCafe = async () => {
    if (!cafeCode || !corporateId) {
      setError('Please enter a valid cafe code.');
      return;
    }
    setError('');
    setSuccessMessage('');

    try {
      // Check if the cafe code exists in the cafes container
      const cafes = await fetchItems(
        'brewserdb',
        'cafes',
        "SELECT * FROM c WHERE c.linkingCode = @cafeCode",
        [{ name: '@cafeCode', value: cafeCode }]
      );

      if (cafes.length === 0) {
        setError('Invalid cafe code.');
        return;
      }

      const cafe = cafes[0];

      // Check for existing pending partnership
      const existingPartnership = await fetchItems(
        'brewserdb',
        'corporate_cafe_partnerships',
        "SELECT * FROM c WHERE c.cafeId = @cafeId AND c.corporateId = @corporateId",
        [
          { name: '@cafeId', value: cafe.id },
          { name: '@corporateId', value: corporateId }
        ]
      );

      const partnershipToUpsert = {
        id: `${corporateId}-${cafe.id}`,
        corporateId,
        cafeId: cafe.id,
        createdAt: new Date().toISOString(),
        status: existingPartnership.length > 0 ? 'linked' : 'pending',
      };

      // Use upsertItem to either create or update the partnership
      await addItem('brewserdb', 'corporate_cafe_partnerships', partnershipToUpsert);
      setLinkedCafes([...linkedCafes, { ...cafe, status: partnershipToUpsert.status }]);

      setCafeCode(''); // Clear the input field
      setSuccessMessage(
        partnershipToUpsert.status === 'linked'
          ? `Successfully linked with ${cafe.cafeName}`
          : `Link request sent to ${cafe.cafeName}`
      );
    } catch (error) {
      console.error('Failed to link with cafe:', error);
      setError('Failed to link with the cafe. Please try again.');
    }
  };

  const styles = {
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      paddingTop: '50px',
    },
    formContainer: {
      padding: '40px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
      textAlign: 'center',
    },
    linkingCode: {
      fontSize: '18px',
      color: '#333',
      backgroundColor: '#f4f4f4',
      padding: '10px',
      borderRadius: '5px',
      marginBottom: '20px',
      fontWeight: 'bold',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '15px',
      borderRadius: '5px',
      border: '1px solid #DDDDDD',
    },
    button: {
      width: '100%',
      backgroundColor: '#F57F17',
      padding: '10px',
      borderRadius: '5px',
      color: '#FFF',
      cursor: 'pointer',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    linkedCafeList: {
      marginTop: '20px',
      textAlign: 'left',
    },
  };

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1>Link Cafes</h1>
        
        {/* Display the corporate linking code */}
        {corporateLinkingCode && (
          <div style={styles.linkingCode}>
            Corporate Linking Code: {corporateLinkingCode}
          </div>
        )}
        
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        
        <input
          style={styles.input}
          type="text"
          placeholder="Enter Cafe Code"
          value={cafeCode}
          onChange={(e) => setCafeCode(e.target.value)}
        />
        <div style={styles.button} onClick={handleLinkCafe}>
          Link Cafe
        </div>
        
        <div style={styles.linkedCafeList}>
          <h3>Linked Cafes:</h3>
          {linkedCafes.length > 0 ? (
            linkedCafes.map((cafe) => (
              <p key={cafe.id}>
                {cafe.cafeName} - {cafe.status || 'pending'}
              </p>
            ))
          ) : (
            <p>No cafes linked yet.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default CorporateCafes;
