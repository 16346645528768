import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Container from '../components/Container';

const UserScreen = () => {
  const navigate = useNavigate();

  const handleRegisterCafe = () => {
    navigate('/caferegistration');
  };

  const handleRegisterCorporate = () => {
    navigate('/corporateregistration');
  };

  const styles = {
    pageWrapper: {
      minHeight: '100vh',
      paddingTop: '100px', // Create space for the fixed header
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    button: {
      padding: '15px 30px',
      fontSize: '18px',
      color: 'white',
      backgroundColor: '#ff7f00',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      marginBottom: '15px',
      width: '100%',
      maxWidth: '250px',
      textAlign: 'center',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
  };

  return (
    <div style={styles.pageWrapper}>
      <Header />
      <Container>
        <button style={styles.button} onClick={handleRegisterCafe}>
          Register a Cafe
        </button>
        <button style={styles.button} onClick={handleRegisterCorporate}>
          Register for a Corporate Account
        </button>
      </Container>
    </div>
  );
};

export default UserScreen;
