// src/services/apiService.js

const BASE_URL = 'https://brewserauth.azurewebsites.net/api';

export const signupUser = async (userData) => {
    const response = await fetch(`${BASE_URL}/SignupFunction`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': process.env.REACT_APP_FUNCTION_KEY,
        },
        body: JSON.stringify(userData),
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Signup failed');
    }
    return data;
};

export const loginUser = async (credentials) => {
    const response = await fetch(`${BASE_URL}/LoginFunction`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': process.env.REACT_APP_FUNCTION_KEY,
        },
        body: JSON.stringify(credentials),
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Login failed');
    }
    return data;
};


// New function to register a cafe
export const registerCafe = async (cafeData) => {
    const response = await fetch(`${BASE_URL}/CreateCafeFunction`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': process.env.REACT_APP_FUNCTION_KEY,
        },
        body: JSON.stringify(cafeData),
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Cafe registration failed');
    }
    return data;
};

export const inviteStaff = async (cafeId, email) => {
    const response = await fetch(`${BASE_URL}/AddStaffFunction`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': process.env.REACT_APP_FUNCTION_KEY,
        },
        body: JSON.stringify({ cafeId, email }),
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Failed to invite staff member');
    }
    return data;
};

export const getCafeForAdmin = async (userId) => {
    const response = await fetch(`${BASE_URL}/GetCafeForAdmin`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': process.env.REACT_APP_FUNCTION_KEY,
        },
        body: JSON.stringify({ userId }),
    });

    const data = await response.json();
    if (!response.ok) {
        throw new Error(data.error || 'Failed to retrieve cafe information');
    }
    return data;
};
