import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { addItem, fetchItems, deleteItem } from '../services/cosmosdbService';

const CafeUsers = () => {
  const [email, setEmail] = useState('');
  const [staffList, setStaffList] = useState([]);
  const [cafeId, setCafeId] = useState(null);
  const [cafeName, setCafeName] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchCafeData = async () => {
      if (!user) {
        console.log('No user data found in context');
        setLoading(false);
        return;
      }
      console.log('Fetching cafe assignments for user ID:', user.userId);

      try {
        const assignments = await fetchItems(
          'brewserdb',
          'cafe_assignments',
          "SELECT * FROM c WHERE c.userId = @userId AND c.role = 'admin'",
          [{ name: '@userId', value: user.userId }]
        );
        console.log(assignments);
        if (assignments.length > 0) {
          const cafeId = assignments[0].cafeId;
          setCafeId(cafeId);

          console.log('Cafe ID found:', cafeId);

          // Fetch the cafe details using cafeId
          const cafes = await fetchItems(
            'brewserdb',
            'cafes',
            "SELECT * FROM c WHERE c.id = @cafeId",
            [{ name: '@cafeId', value: cafeId }]
          );

          if (cafes.length > 0) {
            setCafeName(cafes[0].cafeName);
            console.log('Cafe name found:', cafes[0].cafeName);
          }

          // Fetch staff assignments for the cafe
          const staffAssignments = await fetchItems(
            'brewserdb',
            'cafe_assignments',
            "SELECT * FROM c WHERE c.cafeId = @cafeId",
            [{ name: '@cafeId', value: cafeId }]
          );

          // Fetch user details for each staff assignment
          const staffDetails = await Promise.all(
            staffAssignments.map(async (assignment) => {
              const userDetails = await fetchItems(
                'brewserdb',
                'accounts',
                "SELECT * FROM c WHERE c.id = @userId",
                [{ name: '@userId', value: assignment.userId }]
              );
              if (userDetails.length > 0) {
                return {
                  ...assignment,
                  firstName: userDetails[0].firstName,
                  lastName: userDetails[0].lastName,
                };
              }
              return assignment;
            })
          );

          setStaffList(staffDetails);
        } else {
          setError('You do not have an assigned cafe.');
        }
      } catch (err) {
        console.error('Failed to retrieve cafe information:', err);
        setError('Failed to retrieve cafe information');
      } finally {
        setLoading(false);
      }
    };

    if (user && user.userId) {
      fetchCafeData();
    } else {
      console.log('User context not ready or user ID missing');
      setLoading(false);
    }
  }, [user]);

  const handleInvite = async () => {
    if (!email || !cafeId) {
      setError('Please enter a valid email address and ensure the cafe ID is available.');
      return;
    }
    setError('');
    setSuccessMessage('');

    try {
      // Verify if the email belongs to a valid user
      const userDetails = await fetchItems(
        'brewserdb',
        'accounts',
        "SELECT * FROM c WHERE c.email = @userEmail",
        [{ name: '@userEmail', value: email }]
      );

      if (userDetails.length === 0) {
        setError('The provided email does not belong to a registered user.');
        return;
      }

      const newAssignment = {
        id: Date.now().toString(),
        cafeId: cafeId,
        userId: userDetails[0].id,
        role: 'staff',
      };
      await addItem('brewserdb', 'cafe_assignments', newAssignment);
      setStaffList((prevList) => [
        ...prevList,
        { ...newAssignment, firstName: userDetails[0].firstName, lastName: userDetails[0].lastName, status: 'Assigned' },
      ]);
      setEmail(''); // Clear input
      setSuccessMessage('Staff member assigned successfully.');
    } catch (error) {
      console.error('Failed to assign staff member:', error);
      setError('Failed to assign staff member. Please try again.');
    }
  };

  const handleRemove = async (assignmentId) => {
    try {
      await deleteItem('brewserdb', 'cafe_assignments', assignmentId);
      setStaffList((prevList) => prevList.filter((staff) => staff.id !== assignmentId));
      setSuccessMessage('Staff member removed successfully.');
    } catch (error) {
      console.error('Failed to remove staff member:', error);
      setError('Failed to remove staff member. Please try again.');
    }
  };

  const styles = {
    pageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      minHeight: '100vh',
      paddingTop: '50px',
    },
    formContainer: {
      padding: '40px',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      maxWidth: '400px',
      width: '100%',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      marginBottom: '10px',
      textAlign: 'center',
    },
    input: {
      width: '100%',
      padding: '10px',
      marginBottom: '15px',
      borderRadius: '5px',
      border: '1px solid #DDDDDD',
    },
    button: {
      width: '100%',
      backgroundColor: '#F57F17',
      padding: '10px',
      borderRadius: '5px',
      color: '#FFF',
      cursor: 'pointer',
      textAlign: 'center',
      fontWeight: 'bold',
    },
    staffCard: {
      backgroundColor: '#FFFFFF',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '10px',
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.1)',
    },
    smallText: {
      fontSize: '14px',
      color: '#333',
    },
    errorText: {
      color: '#b71c1c',
      marginBottom: '10px',
    },
    successText: {
      color: 'green',
      marginBottom: '10px',
    },
    removeButton: {
      backgroundColor: '#b71c1c',
      color: '#FFF',
      padding: '5px 10px',
      borderRadius: '5px',
      cursor: 'pointer',
      border: 'none',
      marginTop: '10px',
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.pageContainer}>
      <div style={styles.formContainer}>
        <h1 style={styles.title}>{cafeName || 'Cafe Admin'}</h1>
        <p>Invite additional staff members to use the mobile app.</p>

        {error && <p style={styles.errorText}>{error}</p>}
        {successMessage && <p style={styles.successText}>{successMessage}</p>}

        <input
          style={styles.input}
          type="email"
          placeholder="Enter staff member's email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />

        <div style={styles.button} onClick={handleInvite}>
          Invite Staff
        </div>

        <div>
          {staffList.map((staff) => (
            <div key={staff.id} style={styles.staffCard}>
              <p style={styles.smallText}>{staff.firstName} {staff.lastName}</p>
              <p style={styles.smallText}>{staff.role}</p>
              <button
                style={styles.removeButton}
                onClick={() => handleRemove(staff.id)}
              >
                Remove
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CafeUsers;
